{
  "name": "portal",
  "version": "4.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start-local": "ng serve --configuration=emulator",
    "build": "ng build --output-hashing=all",
    "deploy-dev": "ng build --output-hashing=all && firebase use dev && firebase target:apply hosting portal-dev finstein-dev && firebase deploy --only hosting:portal-dev",
    "deploy-test": "ng build --aot=true --configuration=test --output-hashing=all && firebase use finstein-test && firebase target:apply hosting portal-test finstein-test && firebase deploy --only hosting:portal-test",
    "deploy-stage": "ng build --aot=true --configuration=stage --output-hashing=all && firebase use finstein-stage && firebase target:apply hosting portal-stage finstein-stage && firebase deploy --only hosting:portal-stage",
    "deploy-prod": "ng build --aot=true --configuration=prod --output-hashing=all && firebase use finstein-prod && firebase target:apply hosting portal-prod finstein-prod && firebase deploy --only hosting:portal-prod",
    "deploy-firestore-rules-dev": "firebase use finstein-dev && firebase deploy --only firestore:rules",
    "deploy-firestore-rules-test": "firebase use finstein-test && firebase deploy --only firestore:rules",
    "deploy-firestore-rules-stage": "firebase use finstein-stage && firebase deploy --only firestore:rules",
    "deploy-firestore-rules-prod": "firebase use finstien-prod && firebase deploy --only firestore:rules",
    "test": "ng test --no-watch --no-progress --browsers=ChromeHeadless",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@angular-material-extensions/password-strength": "^16.0.0",
    "@angular/animations": "^17.3.12",
    "@angular/cdk": "^16.2.2",
    "@angular/common": "^17.3.12",
    "@angular/compiler": "^17.3.12",
    "@angular/core": "^17.3.12",
    "@angular/fire": "^16.0.0",
    "@angular/forms": "^17.3.12",
    "@angular/localize": "^17.3.12",
    "@angular/material": "^16.2.2",
    "@angular/platform-browser": "^17.3.12",
    "@angular/platform-browser-dynamic": "^17.3.12",
    "@angular/router": "^17.3.12",
    "@auth0/angular-jwt": "^5.1.2",
    "@google-cloud/pubsub": "4.1.0",
    "@ng-bootstrap/ng-bootstrap": "15.1.1",
    "@ng-select/ng-option-highlight": "^11.1.1",
    "@ng-select/ng-select": "^11.1.1",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@popperjs/core": "^2.11.8",
    "@swimlane/ngx-datatable": "20.1.0",
    "@types/file-saver": "^2.0.5",
    "@types/lodash": "4.14.178",
    "@uiowa/digit-only": "3.2.1",
    "ajv": "^8.12.0",
    "balloon-css": "^1.2.0",
    "bootstrap": "4.6.2",
    "bs-stepper": "^1.7.0",
    "crypto-js": "^4.2.0",
    "datatables.net-bs4": "^1.13.8",
    "file-saver": "^2.0.5",
    "firebase": "^10.7.2",
    "font-awesome": "^4.7.0",
    "inputmask": "^5.0.8",
    "jquery": "^3.6.3",
    "jquery-slimscroll": "^1.3.8",
    "libphonenumber-js": "^1.10.51",
    "lodash": "^4.17.21",
    "moment": "2.30.0",
    "ng-circle-progress": "^1.7.1",
    "ng-recaptcha": "^12.0.2",
    "ng2-currency-mask": "~13.0.3",
    "ng2-pdf-viewer": "10.0.0",
    "ngx-chips": "^3.0.0",
    "ngx-clipboard": "^16.0.0",
    "ngx-editor": "^16.0.1",
    "ngx-filesaver": "^16.0.0",
    "ngx-guided-tour": "^2.0.1",
    "ngx-mask": "^16.2.5",
    "ngx-papaparse": "^7.0.0",
    "ngx-permissions": "^15.0.1",
    "ngx-print": "^1.5.1",
    "ngx-spinner": "^16.0.2",
    "ngx-toastr": "16.2.0",
    "ngx-ui-switch": "^14.0.3",
    "ngx-window-token": "^7.0.0",
    "popper.js": "^1.16.1",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "ua-parser-js": "^1.0.37",
    "uuid": "^9.0.0",
    "xlsx": "^0.18.5",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "17.3.11",
    "@angular-eslint/builder": "17.5.3",
    "@angular-eslint/eslint-plugin": "17.5.3",
    "@angular-eslint/eslint-plugin-template": "17.5.3",
    "@angular-eslint/schematics": "17.5.3",
    "@angular-eslint/template-parser": "17.5.3",
    "@angular/cli": "^17.3.11",
    "@angular/compiler-cli": "^17.3.12",
    "@messageformat/core": "^3.0.1",
    "@types/inputmask": "^5.0.7",
    "@types/jasmine": "~3.5.0",
    "@types/jasminewd2": "~2.0.3",
    "@types/jest": "^29.5.14",
    "@types/mocha": "^10.0.10",
    "@types/node": "^16.18.116",
    "@types/ua-parser-js": "^0.7.39",
    "@types/uuid": "^9.0.4",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "codelyzer": "^6.0.2",
    "eslint": "^8.57.0",
    "firebase-tools": "^12.9.1",
    "jasmine-core": "~5.1.1",
    "jasmine-spec-reporter": "~5.0.0",
    "jszip": "^3.10.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.5.0",
    "protractor": "~7.0.0",
    "ts-node": "~8.3.0",
    "tslint": "~6.1.0",
    "typescript": "~5.4.5"
  },
  "browser": {
    "crypto": false
  }
}
